// Source: https://co.wikipedia.org/wiki/ISO_3166-2
export const corsicanCountryNames: Record<string, string> = {
  AD: "Andorra",
  AE: "Emirati Arabi Uniti",
  AF: "Afghanistan",
  AG: "Antigua è Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarticu",
  AR: "Argentina",
  AS: "Samoa americane",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Isule Åland",
  AZ: "Azerbaigian",
  BA: "Bosnia è Erzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgica",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "San Bartulumeu",
  BM: "Bermuda",
  BN: "Brunei Darussalam",
  BO: "Bolivia (Statu plurinaziunale di)",
  BQ: "Bonaire, Sint Eustatius è Saba",
  BR: "Brasile",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Isula Bouvet",
  BW: "Botswana",
  BY: "Bielorussia",
  BZ: "Belize",
  CA: "Canada",
  CC: "Isule Cocos (Keeling)",
  CD: "Congo (RDC)",
  CF: "Republica Centrafricana",
  CG: "Republica di u Congo",
  CH: "Svizzera",
  CI: "Costa d’Avoriu",
  CK: "Isule Cook",
  CL: "Cile",
  CM: "Camerun",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Capu Verde",
  CW: "Curaçao",
  CX: "Isula Christmas",
  CY: "Cipru",
  CZ: "Republica Cecca",
  DE: "Alemagna",
  DJ: "Djibouti",
  DK: "Danimarca",
  DM: "Dumenica",
  DO: "Republica Duminicana",
  DZ: "Algeria",
  EC: "Equatore",
  EE: "Estonia",
  EG: "Egittu",
  EH: "Sahara occidentale",
  ER: "Eritrea",
  ES: "Spagna",
  ET: "Etiopia",
  FI: "Finlandia",
  FJ: "Figi",
  FK: "Isule Falkland (Maluvine)",
  FM: "Micronesia",
  FO: "Isule Faroe",
  FR: "Francia",
  GA: "Gabon",
  GB: "Reame Unitu",
  GD: "Granata",
  GE: "Georgia",
  GF: "Guiana francese",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Guinea equaturiale",
  GR: "Grecia",
  GS: "Georgia di u Sud è l’Isule Sandwich di u Sud",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Isula Heard è l’Isule McDonald",
  HN: "Honduras",
  HR: "Croazia",
  HT: "Haiti",
  HU: "Ungheria",
  ID: "Indonesia",
  IE: "Irlanda",
  IL: "Israele",
  IM: "Isula di Man",
  IN: "India",
  IO: "Territoriu britannicu di l’oceanu Indianu",
  IQ: "Iraq",
  IR: "Iran (Republica islamica di)",
  IS: "Islanda",
  IT: "Italia",
  JE: "Jersey",
  JM: "Giamaica",
  JO: "Giordania",
  JP: "Giappone",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambogia",
  KI: "Kiribati",
  KM: "Comore",
  KN: "Saint Kitts è Nevis",
  KP: "Corea di u Nordu",
  KR: "Corea di u Sud",
  KW: "Kuwait",
  KY: "Isule Cayman",
  KZ: "Kazakhstan",
  LA: "Laos (Republica democratica pupulare)",
  LB: "Libanu",
  LC: "Santa Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lituania",
  LU: "Luxembourg",
  LV: "Lettonia",
  LY: "Libia",
  MA: "Maroccu",
  MC: "Monaco",
  MD: "Muldavia, Republica di",
  ME: "Montenegro",
  MF: "San Martinu (parte francese)",
  MG: "Madagascar",
  MH: "Isule Marshall",
  MK: "Macedonia di u Nordu",
  ML: "Mali",
  MM: "Myanmar (Birmania)",
  MN: "Mungulia",
  MO: "Macao",
  MP: "Isule Mariana di u Nordu",
  MQ: "Martinica",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldive",
  MW: "Malawi",
  MX: "Messicu",
  MY: "Malesia",
  MZ: "Mozambicu",
  NA: "Namibia",
  NC: "Nova Caledonia",
  NE: "Niger",
  NF: "Isula Norfolk",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Paesi Bassi",
  NO: "Nurvegia",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Nova Zelanda",
  OM: "Oman",
  PA: "Panama",
  PE: "Perù",
  PF: "Polinesia francese",
  PG: "Papuasia Nova Guinea",
  PH: "Filippine",
  PK: "Pakistan",
  PL: "Polonia",
  PM: "Saint Pierre è Miquelon",
  PN: "Isule Pitcairn",
  PR: "Portu Riccu",
  PS: "Palestina, Statu di",
  PT: "Portugallu",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Riunione",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia, Federazione",
  RW: "Ruanda",
  SA: "Saudi Arabia",
  SB: "Isule Solomon",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Svezia",
  SG: "Singapore",
  SH: "Sant’Elena, Ascensione è Tristan da Cunha",
  SI: "Slovenia",
  SJ: "Svalbard è Jan Mayen",
  SK: "Sluvacchia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "Sudan di u Sud",
  ST: "Sao Tome è Principe",
  SV: "El Salvador",
  SX: "San Martinu (parte neerlandese)",
  SY: "Siria, Republica Araba di",
  SZ: "Eswatini",
  TC: "Isule Turks è Caicos",
  TD: "Ciad",
  TF: "Terre australe francese",
  TG: "Togo",
  TH: "Tailanda",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor orientale",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turchia",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania, Republica unita di",
  UA: "Ucraina",
  UG: "Uganda",
  UM: "Isule minore alluntanate di i Stati Uniti",
  US: "Stati Uniti d’America",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Cità di u Vaticanu",
  VC: "Saint Vincent è Grenadines",
  VE: "Venezuela (Republica Bolivariana di)",
  VG: "Isule Vergine Britanniche",
  VI: "Isule Vergine Americane",
  VN: "Viet Nam",
  VU: "Vanuatu",
  WF: "Wallis è Futuna",
  WS: "Samoa",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "Africa di u Sud",
  ZM: "Zambia",
  ZW: "Zimbabwe",

  AN: "Antiglie neerlandese",
  GZ: "Striscia di Gaza",
  XK: "Kosovo",
};
