// Source: https://hu.wikipedia.org/wiki/ISO_3166-1
export const hungarianCountryNames: Record<string, string> = {
  AF: "Afganisztán",
  AX: "Åland",
  AL: "Albánia",
  DZ: "Algéria",
  UM: "USA Amerikai Csendes-óceáni szigetek",
  US: "Amerikai Egyesült Államok",
  AS: "Amerikai Szamoa",
  VI: "Amerikai Virgin-szigetek",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktisz",
  AG: "Antigua és Barbuda",
  AR: "Argentína",
  AW: "Aruba",
  AU: "Ausztrália",
  AT: "Ausztria",
  AZ: "Azerbajdzsán",
  BS: "Bahama-szigetek",
  BH: "Bahrein",
  BD: "Banglades",
  BB: "Barbados",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhután",
  GW: "Bissau-Guinea",
  BO: "Bolívia",
  BA: "Bosznia-Hercegovina",
  BW: "Botswana",
  BV: "Norvégia Bouvet-sziget",
  BR: "Brazília",
  IO: "Brit Indiai-óceáni Terület",
  VG: "Brit Virgin-szigetek",
  BN: "Brunei",
  BG: "Bulgária",
  BF: "Burkina Faso",
  BI: "Burundi",
  CL: "Chile",
  CY: "Ciprus",
  KM: "Comore-szigetek",
  CK: "Cook-szigetek",
  CR: "Costa Rica",
  CW: "Curaçao",
  TD: "Csád",
  CZ: "Csehország",
  DK: "Dánia",
  ZA: "Dél-afrikai Köztársaság",
  GS: "Déli-Georgia és Déli-Sandwich-szigetek",
  SS: "Dél-Szudán",
  KR: "Dél-Korea (Koreai Köztársaság)",
  DM: "Dominikai Közösség",
  DO: "Dominikai Köztársaság",
  DJ: "Dzsibuti",
  EC: "Ecuador",
  GQ: "Egyenlítői-Guinea",
  AE: "Egyesült Arab Emírségek",
  GB: "Egyesült Királyság",
  EG: "Egyiptom",
  CI: "Elefántcsontpart",
  SV: "Salvador",
  ER: "Eritrea",
  MP: "Északi-Mariana-szigetek",
  KP: "Észak-Korea (Koreai NDK)",
  EE: "Észtország",
  ET: "Etiópia",
  FK: "Falkland-szigetek",
  BY: "Fehéroroszország",
  FO: "Feröer",
  FJ: "Fidzsi",
  FI: "Finnország",
  TF: "Francia déli és antarktiszi területek",
  GF: "Francia Guyana Francia Guyana",
  FR: "Franciaország",
  PF: "Francia Polinézia",
  PH: "Fülöp-szigetek",
  GA: "Gabon",
  GM: "Gambia",
  GH: "Ghána",
  GI: "Gibraltár",
  GR: "Görögország",
  GD: "Grenada",
  GL: "Grönland",
  GE: "Grúzia",
  GP: "Guadeloupe Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey Bailiffség",
  GN: "Guinea",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard-sziget és McDonald-szigetek",
  NL: "Hollandia",
  HN: "Honduras",
  HK: "Hongkong",
  HR: "Horvátország",
  IN: "India",
  ID: "Indonézia",
  IQ: "Irak",
  IR: "Irán",
  IE: "Írország",
  IS: "Izland",
  IL: "Izrael",
  JM: "Jamaica",
  JP: "Japán",
  YE: "Jemen",
  JE: "Jersey Bailiffség",
  JO: "Jordánia",
  KY: "Kajmán-szigetek",
  KH: "Kambodzsa",
  CM: "Kamerun",
  CA: "Kanada",
  CX: "Karácsony-sziget",
  BQ: "Karibi Hollandia (Bonaire, Saba, Sint Eustatius)",
  QA: "Katar",
  KZ: "Kazahsztán",
  TL: "Kelet-Timor",
  KE: "Kenya",
  CN: "Kína",
  KG: "Kirgizisztán",
  KI: "Kiribati",
  CC: "Kókusz (Keeling)-szigetek",
  CO: "Kolumbia",
  CD: "Kongói Demokratikus Köztársaság (Zaire)",
  CG: "Kongói Köztársaság (Kongó)",
  XK: "Koszovó",
  CF: "Közép-Afrika",
  CU: "Kuba",
  KW: "Kuvait",
  LA: "Laosz",
  PL: "Lengyelország",
  LS: "Lesotho",
  LV: "Lettország",
  LB: "Libanon",
  LR: "Libéria",
  LY: "Líbia",
  LI: "Liechtenstein",
  LT: "Litvánia",
  LU: "Luxemburg",
  MK: "Észak-Macedónia",
  MG: "Madagaszkár",
  HU: "Magyarország",
  MO: "Makaó",
  MY: "Malajzia",
  MW: "Malawi",
  MV: "Maldív-szigetek",
  ML: "Mali",
  MT: "Málta",
  IM: "Man",
  MA: "Marokkó",
  MH: "Marshall-szigetek",
  MQ: "Martinique Martinique",
  MR: "Mauritánia",
  MU: "Mauritius",
  YT: "Mayotte Mayotte",
  MX: "Mexikó",
  MM: "Mianmar",
  FM: "Mikronézia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongólia",
  ME: "Montenegró",
  MS: "Montserrat",
  MZ: "Mozambik",
  NA: "Namíbia",
  NR: "Nauru",
  DE: "Németország",
  NP: "Nepál",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NF: "Norfolk-sziget",
  NO: "Norvégia",
  EH: "Nyugat-Szahara",
  IT: "Olaszország",
  OM: "Omán",
  RU: "Oroszország",
  AM: "Örményország",
  PK: "Pakisztán",
  PW: "Palau",
  PS: "Palesztina",
  PA: "Panama",
  PG: "Pápua Új-Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PN: "Pitcairn-szigetek",
  PT: "Portugália",
  PR: "Puerto Rico",
  RE: "Réunion",
  RO: "Románia",
  RW: "Ruanda",
  BL: "Saint-Barthélemy",
  KN: "Saint Kitts és Nevis",
  LC: "Saint Lucia",
  MF: "Saint-Martin",
  PM: "Saint-Pierre és Miquelon Saint-Pierre és Miquelon",
  VC: "Saint Vincent és a Grenadine-szigetek",
  SB: "Salamon-szigetek",
  SM: "San Marino",
  ST: "São Tomé és Príncipe",
  SC: "Seychelle-szigetek",
  SL: "Sierra Leone",
  SX: "Sint Maarten",
  ES: "Spanyolország",
  SJ: "Norvégia Spitzbergák és Jan Mayen-sziget",
  LK: "Srí Lanka",
  SR: "Suriname",
  CH: "Svájc",
  SE: "Svédország",
  WS: "Szamoa",
  SA: "Szaúd-Arábia",
  SN: "Szenegál",
  SH: "Szent Ilona",
  RS: "Szerbia",
  CS: "Szerbia és Montenegró",
  SG: "Szingapúr",
  SY: "Szíria",
  SK: "Szlovákia",
  SI: "Szlovénia",
  SO: "Szomália",
  SD: "Szudán",
  SZ: "Szváziföld",
  TJ: "Tádzsikisztán",
  TW: "Tajvan",
  TZ: "Tanzánia",
  TH: "Thaiföld",
  TG: "Togo",
  TK: "Tokelau-szigetek",
  TO: "Tonga",
  TR: "Törökország",
  TT: "Trinidad és Tobago",
  TN: "Tunézia",
  TC: "Turks- és Caicos-szigetek",
  TV: "Tuvalu",
  TM: "Türkmenisztán",
  UG: "Uganda",
  NC: "Új-Kaledónia",
  NZ: "Új-Zéland",
  UA: "Ukrajna",
  UY: "Uruguay",
  UZ: "Üzbegisztán",
  VU: "Vanuatu",
  VA: "Vatikán",
  VE: "Venezuela",
  VN: "Vietnám",
  WF: "Wallis és Futuna Wallis és Futuna",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  CV: "Zöld-foki Köztársaság",

  // Country codes not part of ISO-3166-1 (reserved, deleted or unassigned):
  AN: "Holland Antillák",
  GZ: "Gázai övezet",
};
